@import "../../../variables.scss";
.account-container {
  height: 90vh;
  .create-btn {
    background: $linear-blue-bg;
    width: 168px;
    height: 30px;
    border-radius: 3.3652px;
    padding: 0;
    border: 0px;
  }

  .active-btn {
    background-color: $sub-heading-color;
    border: none;
    margin-left: 20px;
    width: 150px;
    height: 30px;
    font-family: $manrope-regular-font;
    padding: 0;
    font-weight: 500;
  }

  .inactive-btn {
    background: none !important;
    border: none;
    width: 150px;
    height: 30px;
    margin-left: 20px;
    font-family: $manrope-regular-font;
    padding: 0;
    color: $sub-heading-color;
    font-weight: 500;
  }

  .card-body-circle {
    width: 10px;
    height: 10px;
    background-color: $sub-heading-color;
    border-radius: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: $white-color;
    font-weight: 600;
  }
  .add-account-svg {
    width: 18px;
    // padding-bottom: 2px;
  }
  .card-action-images {
    // width: 22px;
    height: 22px;
    margin-right: 5px;
    padding: 1px 1px 1px 1px;
    object-fit: contain;
    cursor: pointer;
  }

  .card-action-images-disabled {
    // width: 22px;
    height: 22px;
    margin-right: 5px;
    padding: 1px 1px 1px 1px;
    object-fit: contain;
    cursor: not-allowed;
    opacity: 0.5;
  }

  .account {
    .iq-card {
      background-color: $midnight-express-1;
      margin-bottom: 0px;
      border-radius: 0px;
    }
    .account-card {
      &.account-details {
        border-radius: 0px;
        border-bottom-width: 0px !important;
      }
      &.account-actions {
        border-radius: 0px;
      }
      .footer {
        display: flex;
        justify-content: space-between;
        div {
          .footer-icons {
            margin: 1px 2px 4px 6px;
            width: 40px;
            height: 25px;
          }
          i {
            color: $jordy-blue;
            padding: 3px 2px 2px 3px;
            font-size: 22px;
            cursor: pointer;
          }
        }
      }

      #content-page > div > div > button > img {
        width: 25px;
        padding-bottom: 2px;
      }
      .row {
        height: 40px;
      }
      .card-heading {
        background: $card-heading-bg;
      }
      .card-time {
        margin: 8px 0 8px 5px;
        font-family: $manrope-regular-font;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $sub-heading-color;
      }
      .card-body {
        height: 75px;
        padding: 0px;
        margin: 10px 0 0 0px;
        font-family: $manrope-regular-font;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $subtext-color !important;
        list-style-type: circle;
        .info {
          font-weight: normal;
          img {
            width: 20px;
          }
        }
      }
      border-radius: 5px 5px 0 0;
      // border: 1px solid rgb(221, 217, 217);
      box-shadow: none;
      .connect-cards {
        h6 {
          font-weight: bold;
          color: $grey-color;
          font-size: 14px;
        }
      }
      div {
        .check {
          background-color: $dodger-blue;
          border-radius: 6px;
          i {
            margin-top: 10px;
            font-size: 22px;
            color: $white-color;
          }
        }
        div {
          .card-title {
            margin-top: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            font-family: $manrope-regular-font;
            font-size: 14px;
            color: $sub-heading-color;
          }
        }
      }
      .iq-card-header {
        padding: 0px;
        height: 33px;
      }
    }
  }
  .account-title {
    // background-color: #e2e2e2;
    // border-top: 4px solid #888888;
    // border-bottom: 3px solid #888888;

    span {
      color: $surface80;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      padding: 10px 0;
    }
  }
  .footer {
    height: 31px;
    .created-by {
      font-size: 12px;
      margin-right: 5px;
      img {
        width: 21px;
      }
    }
  }
  ul {
    list-style: none;
    margin-left: -20px;
  }

  ul li::before {
    content: "\2022";
    color: $sub-heading-color;
    font-size: 25px;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    display: none;
  }
  ul li {
    list-style-type: none;
  }
  .account-icon {
    font-size: 10px;
    color: $royal-blue;
  }
}

.add-account-container {
  min-height: 100vh;
  padding-bottom: 2rem;

  .header {
    font-family: $manrope-regular-font;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    text-align: center;
    color: $sub-heading-color;
    padding-top: 50px;
    background: linear-gradient(94.18deg, #ae68f3 -7.51%, #002bff 115.74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .sub-header-text {
    font-family: $manrope-regular-font;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $subtext-color;
    padding: 1rem 5rem;
  }

  .content-header {
    font-family: $manrope-regular-font;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $sub-heading-color;
  }

  .add-account-content {
    opacity: 0.9;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 $box-shadow-shade;
    background-color: $midnight-express-1;
    margin: auto;
    padding: 40px;
    .account-list {
      min-height: 250px;
    }
    .count-circle {
      width: 20px;
      height: 20px;
      background-color: $sub-heading-color;
      border-radius: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $white-color;
      font-weight: 600;
      &-small {
        width: 20px;
        height: 20px;
        background-color: $sub-heading-color;
        border-radius: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $white-color;
        font-weight: 600;
      }
    }

    .select-account {
      .content-header {
        font-family: $manrope-regular-font;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $sub-heading-color;
      }
      .modal-card {
        &-img {
          height: auto;
          max-width: 100%;
          margin: 0 auto;
          cursor: pointer;
        }

        .types {
          width: 170px;
          height: auto;
          padding: 22px 13px;
          margin: 0px 10px;
          background: #1d2137;
          border-radius: 6.9128px;
          cursor: pointer;
        }
        .types:hover {
          border: 1px solid $card-border-color-2;
        }
        .types-selected {
          border: 1px solid $toggle-button-color;
        }
        &-title {
          font-family: $manrope-regular-font;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: $sub-heading-color;
        }
      }

      .step-margin {
        margin: 2rem 6rem;
      }

      .step-header {
        font-family: $manrope-regular-font;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $sub-heading-color;
      }
      .step-sub-header {
        font-family: $manrope-regular-font;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $subtext-color;
      }
    }

    #add-account {
      .account-loading-img {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 300px;
        text-align: center;
        z-index: 1;
        width: 200px;
      }
      .modal-button-simple {
        padding: 0px;
      }
      .form-field-title {
        color: $anon;
      }
      .step-header {
        font-family: $manrope-regular-font;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $sub-heading-color;
      }
      .step-sub-header {
        font-family: $manrope-regular-font;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $subtext-color;
      }
      .vl {
        border-left: 1px solid $separator-color;
        height: 60px;
      }
    }
  }
  .steps-header {
    ul {
      padding: 0;
      li {
        display: inline-block;
        border-bottom: 2px solid #373453;
        padding-right: 30px;
        padding-bottom: 5px;
        width: 50%;
        .step-number {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          color: $storm-grey;
          text-align: center;
          line-height: 30px;
          border: 1px solid $storm-grey;
          background: transparent;
          margin-bottom: 10px;
        }
        &.activestep {
          border-color: $toggle-button-color;
          .step-number {
            background: $toggle-button-color;
            color: $white-color;
          }
          .step-text {
            color: $white-color;
          }
        }
        &:not(:first-child) {
          padding-left: 20px;
        }
      }
    }
  }
}
