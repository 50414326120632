@import '../../variables.scss';

#cd-sidebar {
  background: 'transparent';
  position: fixed;
  z-index: 999;
  border-right: 0.063rem solid rgba(255, 255, 255, 0.05);
  height: 100%;
  transition: min-width 0.5s ease-in-out;
  font-family: Inter;

  .cd-logo {
    padding: 1.25rem 0px;
  }

  .sidebar-menu {
    padding: 0.563rem;
    width: fit-content;
    margin: auto;
    &:hover {
      background: $violent-violet;
      border-radius: 4px;
      svg {
        fill: $violent-violet;
        path {
          stroke: $toggle-button-color;
          stroke-width: 1.63371;
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      }
    }
  }
  .arrow-icon {
    position: absolute;
    right: 25px;
    top: 70px;
  }
  .sidebar-menu-extended {
    align-items: center;
    display: flex;
    padding: 10px 10px;
    &:hover {
      background: $violent-violet;
      border-radius: 4px;
      svg {
        fill: $violent-violet;
        path {
          stroke: $toggle-button-color;
          stroke-width: 1.63371;
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      }
    }
  }

  .sidebar-menu-selected {
    padding: 0.563rem;
    width: fit-content;
    margin: auto;

    background: $violent-violet;
    border-radius: 4px;
    svg {
      fill: $violent-violet;
      path {
        stroke: $toggle-button-color;
        stroke-width: 1.63371;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
    }
  }

  .sidebar-menu-selected-extended {
    align-items: center;
    display: flex;
    padding: 10px 10px;
    background: $violent-violet;
    border-radius: 4px;
    svg {
      fill: $violent-violet;
      path {
        stroke: $toggle-button-color;
        stroke-width: 1.63371;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
    }
  }
}
.expanded-sidebar {
  min-width: 10.375rem;
}
.collapsed-sidebar {
  min-width: 4.875rem;
  justify-content: center;
}
.sidebar-menu-title {
  padding-left: 18px;
  color: $grey-shade-4;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
