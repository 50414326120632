@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&family=Manrope:wght@300;400;500&display=swap");

* {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

.barChartContainer {
  overflow: scroll;
}

.barBottomAxis {
  color: #787d9e;
  font-size: 12px;
}

.cloudRiskDonut {
  width: 100%;
}

.mapPlotCircle {
  cursor: pointer;
}

.worldMapContainer svg {
  cursor: grab;
}
.worldMapContainer svg:active {
  cursor: grabbing;
}

.worldMapTooltip {
  animation: show 700ms 100ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;
  opacity: 0;
}

@keyframes show {
  100% {
    opacity: 1;
  }
}

body {
  margin: 0;
  font-family: $manrope-regular-font;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
// .row {
//   margin-left: 0px;
//   margin-right: 0px;
// }
// .w-100 {
//   height: 100%;
// }
.gx-4{
  gap:16px;
}
.las {
  font-size: 30px;
  font-weight: bold;
  cursor: pointer !important;
  color: $white-color;
  font-size: 20px;
  font-weight: bolder;
}
.table-bordered {
  tr {
    th {
      cursor: pointer !important;
      .la-arrow-up {
        font-size: 15px !important;
        visibility: hidden;
      }
      .la-arrow-down {
        font-size: 15px !important;
        visibility: hidden;
      }
      &:hover {
        .la-arrow-up {
          visibility: visible;
        }
        .la-arrow-down {
          visibility: visible;
        }
      }
    }
    td {
      cursor: pointer !important;
    }
  }
}
.bg-ignored {
  background: lightgray;
  color: #000 !important;
}
.bg-highlight {
  background: rgb(248, 233, 202);
  color: #000 !important;
}
.bg-active {
  background: none;
}
.bg-deferred {
  background: none;
}
.bg-assigned {
  background: none;
}
.extraClass {
  font-size: 15px !important;
  margin-top: 5px !important;
  left: 20% !important;
}
table {
  select {
    height: 35px !important;
    margin-top: 5px;
  }
}
.modal-content {
  height: 600px;
}
pre {
  background: rgb(239, 239, 241);
}

.table tr th {
  padding: 3px 10px;
  font-size: 12px;
  font-weight: bolder;
  font-weight: 400;
}

.table tr td {
  padding: 3px 10px;
  font-size: 13px;
  font-weight: 400;
  height: 50px;
  vertical-align: middle;
  color: $surface80;
  a {
    color: $surface80;
  }
}

.table thead tr {
  color: $white-color;
  opacity: 0.4;
}

.breach-table tr th:nth-child(1) {
  width: 50px;
}

.breach-table tr th:nth-child(2) {
  width: 180px;
}

.breach-table tr th:nth-child(3) {
  width: 170px;
}

.breach-table tr th:nth-child(4) {
  width: 100px;
}

.breach-table tr th:nth-child(5) {
  width: 300px;
}

.breach-table tr th:nth-child(6) {
  width: 150px;
}

.breach-table tr th:nth-child(7) {
  width: 400px;
}

.breach-table tr td:nth-child(7) {
  text-align: left !important;
}

.risk-table tr td:nth-child(1) {
  text-align: left !important;
}

.risk-table tr th:nth-child(1) {
  width: 200px;
}

.risk-table tr th:nth-child(2) {
  width: 200px;
}

.risk-table tr th:nth-child(3) {
  width: 100px;
}

.risk-table tr th:nth-child(4) {
  width: 120px;
}

.risk-table tr th:nth-child(5) {
  width: 200px;
}

.risk-table tr th:nth-child(6) {
  width: 200px;
}

.risk-table tr th:nth-child(7) {
  width: 200px;
}

.risk-table tr th:nth-child(8) {
  width: 200px;
}

.risk-table tr td {
  height: auto !important;
  padding: 5px;
}

.risk-table tr td img {
  width: 30px;
}

.remediation-table tr th:nth-child(1) {
  width: 150px;
}

.remediation-table tr th:nth-child(2) {
  width: 150px;
}

.remediation-table tr th:nth-child(3) {
  width: 100px;
}

.remediation-table tr th:nth-child(4) {
  width: 350px;
}

.remediation-table tr th:nth-child(5) {
  width: 100px;
}
.breach-table {
  tr {
    th {
      &:nth-child(1) {
        width: 50px;
      }
      &:nth-child(2) {
        width: 180px;
      }
      &:nth-child(3) {
        width: 170px;
      }
      &:nth-child(4) {
        width: 100px;
      }
      &:nth-child(5) {
        width: 300px;
      }
      &:nth-child(6) {
        width: 150px;
      }
      &:nth-child(7) {
        width: 400px;
      }
    }
    td {
      &:nth-child(7) {
        text-align: left !important;
      }
    }
  }
}
.risk-table {
  tr {
    td {
      &:nth-child(1) {
        text-align: left !important;
      }
      height: auto !important;
      padding: 5px;
      img {
        width: 30px;
      }
    }
    th {
      &:nth-child(1) {
        width: 200px;
      }
      &:nth-child(2) {
        width: 200px;
      }
      &:nth-child(3) {
        width: 100px;
      }
      &:nth-child(4) {
        width: 120px;
      }
      &:nth-child(5) {
        width: 200px;
      }
      &:nth-child(6) {
        width: 200px;
      }
      &:nth-child(7) {
        width: 200px;
      }
      &:nth-child(8) {
        width: 200px;
      }
    }
  }
}
.remediation-table {
  tr {
    th {
      &:nth-child(1) {
        width: 150px;
      }
      &:nth-child(2) {
        width: 150px;
      }
      &:nth-child(3) {
        width: 100px;
      }
      &:nth-child(4) {
        width: 350px;
      }
      &:nth-child(5) {
        width: 100px;
      }
      &:nth-child(6) {
        width: 200px;
      }
      &:nth-child(7) {
        width: 350px;
      }
      &:nth-child(8) {
        width: 200px;
      }
    }
  }
}
.select-all-check {
  margin-top: 10px;
}
.card-title {
  margin-bottom: 0;
}
.confirm-alert {
  text-align: center;
  width: 450px;
  padding: 40px;
  background: #777d74;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: $white-color;
  h1 {
    color: $white-color;
  }
}
.confirm-alert-btn {
  width: 160px;
  padding: 10px;
  border: 1px solid $white-color;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: $white-color;
  font-size: 14px;
}
.btn-full {
  width: 100%;
}
.text-danger {
  color: #dc3545 !important;
}
div.vis-tooltip {
  position: absolute;
  visibility: hidden;
  padding: 10px 20px;
  white-space: normal;
  font-size: 14px;
  color: #000;
  background-color: #f5f4ed;
  border-radius: 3px;
  border: 1px solid skyblue;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  z-index: 5;
}
.badge {
  margin: 5px;
  cursor: pointer;
}
.rangeslider-horizontal {
  .rangeslider__fill {
    background: rgb(12, 12, 175) !important;
  }
  height: 7px !important;
  .rangeslider__handle {
    width: 30px;
    height: 30px;
  }
}
.la-cloud-upload-alt {
  font-size: 20px;
}
.la-cloud-download-alt {
  font-size: 20px;
}
.policy-page {
  .iq-card-header {
    min-height: 40px !important;
  }
  .form-control {
    height: 35px !important;
  }
}
.la-spinner {
  animation: spin 2s linear infinite;
}
.la-times-circle {
  color: #fd7e14 !important;
}
.la-check-double {
  color: #007bff !important;
}
.custom_tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  .tooltiptext {
    visibility: hidden;
    width: 400px;
    background-color: black;
    color: $white-color;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 100%;
    margin-left: -400px;
    opacity: 0;
    transition: opacity 1s;
  }
  &:hover {
    .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
}
.white-tr {
  background: $white-color !important;
  color: rgb(83, 83, 83) !important;
  > th {
    padding: 0.75rem !important;
  }
}
.iq-top-navbar {
  background: $card-background-color;
  padding: 0px 0px 0 60px;
  .iq-navbar-custom {
    .navbar-breadcrumb {
      margin: 40px 0 10px 10px;
      @media only screen and (max-width: 1199px) {
        margin: 40px 0 10px 5px;
      }
      @media only screen and (max-width: 992px) {
        margin: 20px 0 20px 10px;
      }
    }

    .navbar {
      background: $card-background-color;
      .navbar-collapse {
        .navbar-nav {
          .nav-item {
            div {
              img {
                width: 30px;
                cursor: pointer;
              }
              h6 {
                span {
                  color: $risk-color;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    min-height: 60px;
    .main-nav {
      display: none;
    }
    &.menu-toggled {
      .main-nav {
        display: block;
      }
      .ri-menu-3-line {
        display: none;
      }
      .ri-close-fill {
        display: block !important;
      }
    }
  }
}
.navbar-breadcrumb {
  border-radius: 5px;
  padding: 3px 6px;
  h6 {
    font-size: 14px;
    a {
      color: #939fbc;
    }
  }
  @media only screen and (max-width: 1199px) {
    padding: 5px 5px;
    h6 {
      font-size: 12px;
    }
  }
}
.navbar-breadcrumb-active {
  background: #676f82;
  > h6 {
    > a {
      color: $white-color !important;
      font-weight: 500;
    }
  }
}
.breadcumb-right {
  background: #3f3f3f;
  padding: 5px;
  margin: 10px 4px;
  height: 80px;
  border-radius: 5px;
  .icon {
    background: #302f2f;
    box-shadow: inset 0 1px 2px 0 #000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
}
.breadcumb-right-config {
  background: #3f3f3f;
  padding: 2px;
  margin: 10px 10px;
  height: 80px;
  border-radius: 5px;
  width: 180px;
  div {
    i {
      margin-top: 12px;
      font-size: 30px;
      color: #c8d8ff;
    }
    span {
      h6 {
        color: #c8d8ff;
        margin-top: 10px !important;
        font-size: 15px;
      }
      p {
        font-size: 13px;
        color: $white-color;
      }
    }
  }
}
.card-body-small {
  padding: 0.55rem;
}

.text-sky {
  color: #6878a4;
}

.badge-primary {
  border: 1px solid #3f5ea7 !important;
  background: $white-color !important;
  color: #3f5ea7;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  .las {
    color: #3f5ea7 !important;
    font-size: 10px;
    margin-left: 10px;
    cursor: pointer;
  }
}
.card-title-small {
  font-size: 12px;
}
.ma-0 {
  padding: 0px;
}
.iq-mb-2 {
  margin-bottom: 10px !important;
}
.content-page {
  // padding: 100px 0px 0px 60px;
  @media only screen and (max-width: 992px) {
    // padding: 68px 0px 0px 60px;
  }
}
.curve-line {
  width: 95%;
  margin-top: -10px;
}

.card-number {
  font-size: 20px;
}

.pa-r-0 {
  padding-right: 0px;
  padding-left: 15px;
}

.risk-card-sm {
  height: 90px !important;
}
.risk-card {
  background: rgba(74, 79, 90, 1);
  width: 90px;
  float: right;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
  padding: 5px;
  .score {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: $score-elevated-color;
    color: #000;
    margin: auto;
    margin-top: 0.5em;
    justify-content: center;
    align-items: center;
    h6 {
      font-weight: bold;
      text-align: center;
      font-size: 20px;
    }
  }

  p {
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .score-low-level {
    color: $score-low-color !important;
  }

  .score-moderate-level {
    color: $score-moderate-color !important;
  }

  .score-high-level {
    color: orange !important;
  }

  .score-elevated-level {
    color: $score-elevated-color !important;
  }

  .score-low-text {
    color: $score-low-color !important;
  }
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
.data-store {
  overflow-x: auto;
  overflow-y: hidden;
}
.toggle-btn-one {
  background: $sub-heading-color;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 5px;
  border: none;
  color: $white-color;
  font-size: 10px;
  font-weight: bold;
}
.toggle-btn-two {
  border: 2px solid $sub-heading-color;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 3px;
  background: $white-color;
  color: $sub-heading-color;
  font-size: 10px;
  font-weight: bold;
}
.la-angle-right {
  color: $card-background-color;
  // background: #919191;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 15px;
  padding: 2px;
  cursor: pointer;
}
.la-angle-left {
  color: $card-background-color;
  // background: #919191;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 15px;
  padding: 2px;
  cursor: pointer;
}

.disabled {
  background: rgba(255, 255, 255, 0.05);
  cursor: not-allowed !important;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 4px solid #e9edf4;
  position: relative;
  cursor: pointer;
  font-size: 10px;
}

.thin-dot {
  border: 1px solid #919191 !important;
}

.dot-green {
  background: rgb(91, 254, 91);
  &:hover {
    &::after {
      content: "";
      width: 15px;
      height: 15px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 4px solid rgb(252, 250, 250);
      position: absolute;
      display: block;
    }
    &::before {
      content: "";
      width: 15px;
      height: 15px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 12px solid rgba(0, 255, 42, 0.3);
      position: absolute;
      display: block;
    }
  }
}

.dot-red {
  background: rgb(245, 64, 94);
  &:hover {
    &::after {
      content: "";
      width: 15px;
      height: 15px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 4px solid rgb(252, 250, 250);
      position: absolute;
      display: block;
    }
    &::before {
      content: "";
      width: 15px;
      height: 15px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 12px solid rgba(241, 72, 134, 0.3);
      position: absolute;
      display: block;
    }
  }
}
.dot-sky {
  background: rgb(54, 105, 150);
  &:hover {
    &::after {
      content: "";
      width: 15px;
      height: 15px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 4px solid rgb(252, 250, 250);
      position: absolute;
      display: block;
    }
    &::before {
      content: "";
      width: 15px;
      height: 15px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 12px solid rgba(63, 121, 157, 0.3);
      position: absolute;
      display: block;
    }
  }
}
.dot-orange {
  background: rgb(235, 158, 57);
  &:hover {
    &::after {
      content: "";
      width: 15px;
      height: 15px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 4px solid rgb(252, 250, 250);
      position: absolute;
      display: block;
    }
    &::before {
      content: "";
      width: 15px;
      height: 15px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 12px solid rgba(209, 172, 86, 0.3);
      position: absolute;
      display: block;
    }
  }
}
.dot-orange.active {
  &::after {
    content: "";
    width: 15px;
    height: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 4px solid rgb(252, 250, 250);
    position: absolute;
    display: block;
  }
  &::before {
    content: "";
    width: 15px;
    height: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 12px solid rgba(209, 172, 86, 0.3);
    position: absolute;
    display: block;
  }
}
.dot-sky.active {
  &::after {
    content: "";
    width: 15px;
    height: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 4px solid rgb(252, 250, 250);
    position: absolute;
    display: block;
  }
  &::before {
    content: "";
    width: 15px;
    height: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 12px solid rgba(63, 121, 157, 0.3);
    position: absolute;
    display: block;
  }
}
.dot-red.active {
  &::after {
    content: "";
    width: 15px;
    height: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 4px solid rgb(252, 250, 250);
    position: absolute;
    display: block;
  }
  &::before {
    content: "";
    width: 15px;
    height: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 12px solid rgba(241, 72, 134, 0.3);
    position: absolute;
    display: block;
  }
}

.dot-white.active {
  &::after {
    content: "";
    width: 12px;
    height: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 2px solid #4a4f5a;
    position: absolute;
    display: block;
  }
  &::before {
    content: "";
    width: 12px;
    height: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 5px solid $white-color;
    position: absolute;
    display: block;
  }
}

.dot-green.active {
  &::after {
    content: "";
    width: 15px;
    height: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 4px solid rgb(252, 250, 250);
    position: absolute;
    display: block;
  }
  &::before {
    content: "";
    width: 15px;
    height: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 12px solid rgba(0, 255, 42, 0.3);
    position: absolute;
    display: block;
  }
}
.integration-inline {
  li {
    margin: 0px 40px 0px 0px !important;
    margin-left: -10px !important;
  }
  .focus-img {
    width: 25px;
    height: 25px;
    margin-top: -10px;
  }
  span {
    font-size: 12px;
  }
}
.inline {
  margin-top: -8px;
  display: inline-block;

  > li {
    display: inline-block;
    margin: 0px 20px;
    > span {
      font-size: 12px;
      font-size: 12px;
      margin-left: -10px;
    }
  }
}
.error-bg {
  background: $error-color;
}

.leaplabs {
  min-height: 157px;
  margin: 17px 5.5px 0 0;
  border-radius: 2px;
  background-color: $white-color;
  margin-top: 40px;
  cursor: pointer;
  .row {
    div {
      .score {
        margin-top: 15px;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        margin-right: 10px;
        span {
          font-size: 20px;
          color: $white-color;
        }
      }

      .score-small {
        margin-top: 10px;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        margin-right: 10px;
        line-height: 1.4;
        font-size: 12px;
        padding-top: 4px;
        span {
          font-size: 14px;
          color: $white-color;
        }
      }

      .hacker {
        margin-left: 10px;
        margin-top: 20px;
        img {
          width: 35px;
          margin-left: 0px;
        }
      }
      span {
        font-size: 9px;
        color: rgb(160, 14, 14);
        font-weight: bold;
      }
    }
  }
  .header {
    border-bottom: 1px solid $card-border-color;
  }
  .active {
    background: rgb(255, 222, 222);
    border-radius: 5px 0px 0px 5px;
    height: 155px;
  }
  .title-1 {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #274897;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .title-4 {
    font-size: 14px;
    font-weight: 500;
    color: grey;
    margin-top: 8px;
  }
  .title-2 {
    color: grey;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
  }
  .title-3-container {
    display: flex;
    .field {
      min-width: 100px;
    }
    .title-3 {
      color: grey;
      font-size: 12px;
      line-height: 20px;

      .font-bold-600 {
        font-weight: 600;
        width: 200px;
      }
    }
  }
  .second-tab-container {
    // padding-left: 30px;
    div {
      .title-4 {
        font-size: 15px;
        color: $grey-shade;
      }
      .title-3 {
        color: grey;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}

.leaplabs-low {
  border-left: 5px solid $score-low-color;
}

.leaplabs-moderate {
  border-left: 5px solid $score-moderate-color;
}

.leaplabs-high {
  border-left: 5px solid $score-high-color;
}

.leaplabs-elevated {
  border-left: 5px solid $score-elevated-color;
}

.ma-pa-0 {
  margin: 0px;
  padding: 0px;
}
.link {
  color: $sub-heading-color !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.triangle-center-header {
  width: 0;
  height: 0;
  border-top: 100px solid #302f2f;
  border-right: 100px solid transparent;
}
.triangle-header-small {
  width: 0;
  height: 0;
  border-bottom: 68px solid #000000;
  border-left: 68px solid transparent;
  float: right;
  padding-right: 5rem;
  position: absolute;
  right: 0;
  z-index: -1;
}
.sign-in-page {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: #4377f7;
    font-weight: 600;
    text-transform: capitalize;
  }
  .container {
    .logo-container {
      width: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      div {
        margin: 0px 10px;
        h3 {
          line-height: 1em;
        }
      }
    }
  }
  height: 100vh;
  .sign-in-page-footer {
    margin-bottom: 10px;
  }
  .terms {
    color: $score-low-color;
    font-size: 14px;
    .eula-url {
      text-decoration: underline;
    }
  }
  p {
    color: $score-low-color;
    font-size: 13px;
    margin-top: 10px;
  }
  .signup-btn {
    border: solid 1px $score-low-color;
    background: none;
    color: $score-low-color;
    width: 300px;
    border-radius: 20px;
    &:hover {
      background: $score-low-color;
      color: $white-color;
    }
  }
  .container {
    div {
      div {
        .sign-up-from {
          margin-top: 6em !important;
        }

        .sign-in-from {
          .title {
            color: $white-color;
          }
          .description {
            color: $white-color;
            opacity: 0.6;
            font-size: 14px;
          }
          background: rgba(255, 255, 255, 0.06);
          border-radius: 8px;
          center {
            img {
              height: 150px;
              margin-top: 10px;
            }
          }
          form {
            .login-button {
              cursor: pointer;
              background: rgba(255, 255, 255, 0.02);
              border: 1px solid rgba(255, 255, 255, 0.04);
              border-radius: 3px;
              padding: 10px;

              text-align: left;
              img {
                width: 20px;
                margin-right: 1em;
                margin-top: 0.3em;
                float: left;
              }
            }
            .form-group {
              .form-label {
                color: $white-color !important;
                font-size: 0.9em;
              }
              .form-control {
                background: rgba(255, 255, 255, 0.02);
                border: 1px solid rgba(255, 255, 255, 0.04);
                border-radius: 3px;
                color: $white-color !important;
              }
            }
            div {
              .btn {
                background: $button-primary-color;
                // border-radius: 20px;
              }
            }
            p {
              color: $score-low-color;
              font-size: 13px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .sign-in-from {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .sign-in-from {
    width: 40%;
  }
}
@media only screen and (min-width: 992px) {
  .sign-in-from {
    width: 40%;
  }
}

.card-circle-high {
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  color: $white-color;
}

.card-circle-high-number {
  width: 40px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  background-color: #c83939;
  color: $white-color;
  margin-top: 20px;
  span {
    color: $white-color !important;
  }
}

.card-circle-medium {
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  background-color: #e0a44c;
  color: $white-color;
}

.card-circle-medium-number {
  width: 40px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  background-color: #e0a44c;
  color: $white-color;
  margin-top: 20px;
  span {
    color: $white-color !important;
  }
}

.card-circle-low {
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  background-color: #81a7cd;
  color: $white-color;
}

.card-circle-low-number {
  width: 40px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  background-color: #81a7cd;
  color: $white-color;
  margin-top: 20px;
  span {
    color: $white-color !important;
  }
}

.text-danger {
  color: $error-color !important;
}

.pointer {
  cursor: pointer;
}

.control-label-sm {
  font-size: 13px;
}

.btn-primary {
  background: $primaryBlue;
  border: none;
  padding: 6px 15px;
  font-weight: 300;
  border-radius: 1.8px;
  &hover {
    background: $primaryBlue;
    border: 1px solid $primaryBlue;
  }
}

.btn-long {
  width: 200px;
}

.btn-secondary {
  background: $secondary-color;
  border: none;
  border-radius: 1.8px;
  padding: 6px 15px;
  font-weight: 300;
}

.font-weight-md-bold {
  font-weight: 500;
}

.iq-sidebar {
  .iq-sidebar-logo {
    a {
      img {
        width: 50px;
        max-width: 50px;
        // margin-right: 10px;
        margin-top: 17px;
      }
    }
  }
  .tabs {
    text-align: center;
    background: none;
    cursor: pointer;

    &hover {
      background: $sidebar-icon-background;
    }
    i {
      color: $sidebar-icon;
      font-size: 25px;
    }
  }

  .tab-active {
    background: $sidebar-active-background;
  }
}
.scatter-plot-chart {
  background-color: $chart-background-color;
}
.text-sky {
  color: $sub-heading-color;
  font-weight: bold;
}

.la-sort {
  font-size: 13px;
  bottom: 7px;
}
.error-message {
  font-size: 10px;
  color: $error-color;
}
.active-error {
  border-right: 1px solid $card-border-color;
}
.justify-content-center {
  display: flex;
  justify-content: center;
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.mandatory-icon {
  color: $error-color;
  font-size: 18px;
}

.wrap-with-ellipsis {
  width: 215px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.disabled-link {
  text-decoration: none;
  opacity: 0.8;
}

.active-link {
  text-decoration: underline;
}

.score-low {
  background: $score-low-color !important;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.score-moderate {
  background: $score-moderate-color;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.score-high {
  background: $score-high-color;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.score-elevated {
  background: $score-elevated-color;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.pagination-btn-first {
  padding: 2px 10px;
  border-radius: 3px;
  margin-right: 10px;
  color: $white-color;
  font-size: 12px;
  cursor: pointer;
}

.pagination-btn-last {
  padding: 2px 10px;
  border-radius: 3px;
  margin-right: 10px;
  color: $white-color;
  font-size: 12px;
  cursor: pointer;
}

.pagination-btn-disabled {
  cursor: not-allowed;
}

.opacity-loader {
  opacity: 0.5;
}

.btn-primary:disabled {
  color: #fff !important;
  border-color: $sub-heading-color !important;
  opacity: 0.3 !important;
}

.btn-primary:hover,
.btn-primary:active {
  color: #fff !important;
  border-color: $radio-button-color !important;
}
.btn-primary.focus,
.btn-primary:focus {
  color: #fff !important;
  background-color: $radio-button-color !important;
  border-color: $radio-button-color !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.block-ui-overlay {
  background: $violent-violet !important;
}

.table-border-less td,
.table-border-less th {
  border: none !important;
}

.table-border-partial td,
.table-border-partial th {
  // border: none !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

.btn-primary {
  background: $primaryBlue !important;
}
.btn-primary-curved {
  border-radius: 30px;
}

.react-toggle--checked .react-toggle-thumb {
  left: 30px !important;
  border-color: #19ab27;
}

.form-control {
  background: $input-background !important;
  border-radius: 3px !important;
  color: $surface80 !important;
  font-weight: 300;
}

.table td,
.table th {
  border: none;
}

.float-right {
  float: right;
}

.table thead th {
  vertical-align: middle !important;
}

.card {
  background: $card-background-color-v1;
}

.modal-content .modal-body {
  border: none !important;
}
.sub-title-popup-content {
  border: unset !important;
  margin-left: -150px !important;
  margin-top: 1rem !important;
  max-width: 600px !important;
}
.title-popup-content {
  border: unset !important;
  margin-left: -0.6rem !important;
  margin-top: 1.9rem !important;
}
.title-popup-body {
  background-color: $tangaroa;
  border: unset;
}
.user-dropdown {
  font-size: 12px;
}
.label {
  color: $spun-pearl;
  font-size: 14px;
  font-weight: 400;
}
.datastore-filters {
  .accounts-dropdown__single-value {
    color: #878993 !important;
  }

  .accounts-dropdown__option {
    background: none;
    color: $spun-pearl !important;
  }
  // .accounts-dropdown__indicators {
  //   display: none;
  // }
  .accounts-dropdown {
    .accounts-dropdown__control {
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
    }
    .accounts-dropdown__control--is-focused {
      outline: none;
    }
    .accounts-dropdown__single-value {
      font-size: 14px;
    }
    .accounts-dropdown__value-container {
      padding: 2px 6px;
    }
    .accounts-dropdown__indicator {
      padding-left: 0px;
    }
    .accounts-dropdown__indicator-separator {
      display: none !important;
    }
    .accounts-dropdown__menu {
      background: $midnight-express;
      width: 9.375rem;
      border: 1px solid $midnight-express-blue;
      .accounts-dropdown__menu-list {
        width: 100%;
        background: $midnight-express;
        .accounts-dropdown__option {
          margin-top: 0.125rem;
          margin-bottom: 0.125rem;
          &:hover {
            background: $violent-violet;
            color: $toggle-button-color;
          }
        }

        .accounts-dropdown__option--is-selected {
          background: $violent-violet;
          color: $toggle-button-color;
        }
      }
    }
  }
}

.accounts-dropdown__control,
.select__control {
  background: $onboarding-icons !important;
  border: none !important;
  outline: none;
  border-radius: 3px;
}
.modal-header {
  border-bottom: 1px solid $modal-header-border;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 4.375rem;
  height: 2.375rem;
  line-height: 2.875rem;
  text-align: center;
  pointer-events: none;
}

.ball-grid-pulse > div {
  background-color: $toggle-button-color !important;
}
.modal {
  backdrop-filter: blur(5px);
}

.select__multi-value {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $white-color;
  border-radius: 2px;
  padding: 3px 3px 3px 5px;
  border: 1px solid $chip-background;
  width: fit-content;
  overflow: hidden;
  background: $midnight-express !important;
  color: $chip-font;
  div {
    color: $chip-font !important;
  }

  .close-icon {
    width: 16px;
    height: 16px;
    float: right;
    object-fit: contain;
    cursor: pointer;
    border-radius: 50%;
  }
}
.select__multi-value__remove:hover {
  background: none !important;
  cursor: pointer;
}

.sensitive-tags-dropdown__control {
  background: $input-background !important;
  .sensitive-tags-dropdown__single-value {
    color: $white-color;
  }
}

.sensitive-tags-dropdown__menu {
  background: $input-background !important;
  div {
    color: $royal-blue !important;
    font-weight: 600;
  }
}

.table-responsive {
  border-radius: 4px;
}
// CSS for all the table headers and
.table {
  background: $card-background-color-v1;
  border-collapse: separate !important;
  border-spacing: 0px !important;
  thead {
    tr {
      border-radius: 2px;
      opacity: 1;
      svg {
        width: 16px;
      }
      th {
        border: unset;
        border-radius: 2.23843px;
        margin-bottom: 5px;
        color: $surface50;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        border-bottom: 1px solid $surface20;
        text-transform: capitalize;
      }
    }
  }

  tbody {
    tr {
      background: $card-background-color-v1;
      &:hover {
        background: $surface0;
      }
      td {
        border-bottom: 1px solid $surface20 !important;
      }
    }
  }
}
.score-low-svg {
  g {
    path:nth-child(1) {
      fill: $score-low-color;
    }
  }
}

.score-moderate-svg {
  g {
    path:nth-child(1) {
      fill: $score-moderate-color;
    }
  }
}

.score-high-svg {
  g {
    path:nth-child(1) {
      fill: $score-high-color;
    }
  }
}

.score-elevated-svg {
  g {
    path:nth-child(1) {
      fill: $score-elevated-color;
    }
  }
}

.sensitive-tags-dropdown__control {
  border: none !important;
}

.flex {
  display: flex;
}

.font-13 {
  font-size: 13px;
}

// Hide Arrows From Input Number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ml-15 {
  margin-left: -15px;
}

.text-light-white {
  color: $card-border-color;
  font-weight: 300;
}

.w-70 {
  width: 70% !important;
}

.MuiMenu-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background: #191c2e;
}

.overflow-y {
  overflow-y: auto;
}

.overflow-x {
  overflow-x: auto;
}

.margin-auto {
  margin: auto;
}
